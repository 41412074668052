












































import Vue from 'vue';
import AliOss from '@/utils/alioss';

export default Vue.extend({
  props: {
    fileList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      ext: '',
      file: null,
      previewVisible: false,
      previewImage: '',
      list: [],
    };
  },
  watch: {
    fileList(val) {
      this.previewImage = val[0].url;
    },
  },
  methods: {
    handleCancel() {
      this.previewVisible = false;
    },
    /**
     * @description: 上传文件
     * @param {*} params
     * @return {*}
     */
    async uploadFile(params: any) {
      this.file = params.file;
      let data = '';
      if (this.file) {
        data = await new AliOss().upload(this.file);
      }
      this.$emit('update:fileList', [
        {
          url: data,
        },
      ]);
    },
    dellist() {
      this.$emit('update:fileList', [{ url: '' }]);
    },
    async uploadImg(): Promise<string> {
      let data = '';
      if (this.file) {
        data = await new AliOss().upload(this.file);
      }
      return new Promise((resolve) => {
        resolve(data);
      });
    },
    beforeUpload(file: File) {
      const fileName = file.name;
      this.ext = fileName.replace(/.*\./, '').toLowerCase();
      if (!['bmp', 'jpg', 'png', 'gif', 'webp', 'jpeg'].includes(this.ext)) {
        this.$message.error('所传素材格式不支持');
        return false;
      }
      return true;
    },
  },
});
